/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
    display: none;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ffffff;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #F5DF99;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #000000;
  }
  
  /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */

  .bm-menu {
    background: #4B8673;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    list-style-type: none;
  } .bm-item-list li {
    list-style-type: none;
    padding: 20px;
    list-style-type: none;
    width: auto;
    height: inherit;
    transition: 0.3s linear;
  
  }.bm-item-list li a {    
    text-decoration: none;
    list-style-type: none;
    color: #ffffff;
    height: 100%;
    width: 100%;
  }

  .bm-item-list ul li:hover,
  .bm-item-list ul li.active{
    background: white;
  } 

  .bm-item-list ul li:hover a,
  .bm-item-list ul li.active a{
    color:#EBD671;
    /* background: white; */
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    color: #d1d1d1;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  }@media screen and (min-width: 770px) {
    .bm-menu{
      display: none;
    }
  }

  #links{
    cursor: pointer;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  .bm-item:hover {
    color: #ffffff;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }