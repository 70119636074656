/* @tailwind base;
@tailwind components;
@tailwind utilities; */


.home {
  position: absolute;
   height: 100vh;
  width: 100%;
  background-color: #111111;
}

#home-heading {
  position: absolute;
  color: #fff;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  height: auto;
  font-size: xx-large;
} #home-heading1 {
  padding:20px;
  position: relative;
  color: #fff;
  top: 50%;
  width: 100%;
  height: auto;
  font-size: xx-large;
} #home-heading2{
  position: relative;
  color: #fff;
  transform: translateY(-50%); 
  top: 50%;
  width: 100%;
  height: auto;
  font-family:"Lucida Handwriting";
  font-style:italic;
  font-size: xxx-large;
}

