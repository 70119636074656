
.title {
    position: relative;
    height: 60vh;
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),
                    url('./rawdah-assets/pexels-steven-jiashu-online-class-medium.jpg');   
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} .title .title-info {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
} .title .title-info h1 {
    position: relative;
    font-size: 50px;
    color: #fff;
    font-weight: 350;
} .title .title-info p {
    position: relative;
    font-size: 40px;
    color: #fff;
    font-weight: 250;
}
/* --------------------------------------------------------------------------------------- */
.schedule {
    position: relative;
    /* height: 40vh;
    width: 100%; */
    background-color: #000;
    padding: 150px;
    /* overflow: hidden; Prevents child from overflowing */
} @media screen and (max-width: 980px) {
    .schedule {
        padding: 100px 50px 70px 50px;
        position: relative;

    }
}
.schedule .schedule-title h1 {
    position: absolute;
    padding-bottom: 50px;
    color: #fff;
    font-weight: 300;
    top: 30%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
} @media screen and (max-width: 980px) {
    .schedule .schedule-title h1 {
        top: 20%;
    }
}
.schedule .grid-schedule-container {
    left: 3%;
    display:inline-grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    background-color: #000;
    padding: 5px;
    color: #fff;
    font-weight: bold;
} @media screen and (max-width: 980px) {
    .schedule .grid-schedule-container{
        gap: 15px;
    }
}
  /* --------------------------------------------------------------------------------------- */
.class-container{
    padding: 10px;
    background-color:#F5DF99;
} .class-container .class-list {
    background-color: #fff;
    padding: 50px;
    float: center;
} @media screen and (max-width: 980px){
    .class-container .class-list {
        padding: 30px;
    }
} .class-container .class-list h1 {
    padding-top: 30px;
    padding-bottom: 60px;
    font-weight: 300;
    color: #000;
}

.class-container .class-list .grid-class-container {
    /* position: absolute; */
    float: center;
    display: inline-grid;
    grid-template-columns: auto auto;
    /* transform: translateY(-50%,-50%); */
    gap: 20px;
    /* top: 50%; */
    /* left: 3%; */
} @media screen and (max-width: 980px){
    .class-container .class-list .grid-class-container {
        grid-template-columns: auto;
    }
}.grid-class-container .grid-item h4{
    text-align: left;   
}


/*CLAMP-LINE STYLES*/
.clamp-lines__button {
    background: none;
    border: 0;
    color: #187cc8;
    cursor: pointer;
    padding: 0;
} .clamp-lines {
    /* border-bottom: 1px solid #acacac36; */
    border-radius: .25em;
    font-family: sans-serif;
    margin: 1em;
    padding: 0em .75em .75em .75em;
    text-align: left;
    font-weight: 300;
} .details {
    white-space: pre-wrap;
} .details::first-line {
    font-weight: 550;
}
  /* Preserve line breaks characters*/
.overview {
    border-radius: .25em;
    font-family: sans-serif;
    margin: 1em;
    padding: .75em .75em 0em .75em;
    text-align: left;
    font-weight: 300;
    white-space: pre-wrap;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif

} .overview::first-line{
    font-weight: 550;
}