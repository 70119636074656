html, body
 {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  background: #f1f1f1;
  height: 100%;
} 

.page-wrapper{
  position: relative;
  padding: 60px 0px 0px;
  background-color: #111111;
} #page-title {
  position: relative;  
  margin: 5px;
  padding: 20px 100px 0px;
  text-align: center;
  color: #f1f1f1;
  font-size: 30px;
} @media screen and (max-width: 980px) {
  #page-title {
    padding: 20px 50px 0px;

  }
}#page-subheading {
  color:#f1f1f1;
  text-align: center;
  padding: 0px 100px 0px;
  margin-bottom: 5px;
  padding-bottom: 20px;
}

.contact-wrapper{
  position: relative;
  grid-template-columns:  1.2fr 1fr;
  display: grid;
  height: 90vh;
  width: 100%; 
  padding: 0px 50px 150px;
  background-color: #111111;
} @media screen and (max-width: 980px) {
  .contact-wrapper {
    grid-template-columns: auto;
    padding: 0px 20px 50px;
    height: 100%;
  }
}

.grid-item1{
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),
                    url('./rawdah-assets/96th_street_event.jpg');   
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 60px;
  
} @media screen and (max-width: 980px){
  .grid-item1 {
    background-position: center;
    padding: 80px 80px;
  }
}.heading-container {
  position: relative;
  text-align: center;
  transform: translateY(-50%);
  top: 40%;
} #contact-title {
  color: #f1f1f1;
  font-size: 40px;
  font-weight: 350;
 } #contact-subheading {
  color: #f1f1f1;
  font-size: 25px;
  font-weight: 250;
} @media screen and (max-width: 980px) {
  #contact-title{
    font-size: 25px;
  } #contact-subheading {
    font-size: 15px;
  }
}

.grid-item2{
  width: 100%;
  padding: 10px;
  background-color: #F5DF99
}.grid-item2-container{
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 60px 50px 0px;
}.form-container{
  position: relative;
  text-align: center;
  transform: translateY(-50%);
  top: 40%;
} #form-title{
  color: black;
} @media screen and (max-width: 980px) {
  #form-title{
    font-size: 20px;
  }
}
 
form {
  max-width: 600px;
  text-align: center;
  margin: 20px auto;
} .input-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
} @media screen and (max-width: 980px) {
  .input-row{
    display: inline;
  }
}

.input-row .input-group{
  flex-basis: 45%;
} @media screen and (max-width: 980px) {
  .input-group{
    margin-bottom: 25px;
  }
} input{
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
} label{
  display: block;
  text-align: left;
  margin-bottom: 6px;
  color: #000000;
  font-size: 15px;
} @media screen and (max-width: 980px) {
  label{
    margin-top: 10px;
  }
}textarea{
  width: 100%;
  border: 1px solid #ccc;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
} #input-submit{
  background-color: #111111;
  width: 100px;
  border: none;
  outline: none;
  color: white;
  height: 35px;
  border-radius: 30px;
  margin-top: 20px;
  box-shadow: 0px 2px 5px 0px rgba(67, 67, 67, 0.3);
  cursor: pointer;
} #input-submit:hover {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
}