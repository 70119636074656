* {
    box-sizing: border-box;
}
.navbar{
    position:fixed; 
    top: 0;
    height:60px;
    width:100%;
    background: #4B8673;
    display:flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    z-index: 999999;
}

.navbar .logo{
    position: relative;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    font-weight: 750;
    padding: 10px;
    letter-spacing: 0.5px;
  
}
.navbar ul{
    position: relative;
    height: 100%;
    display: flex;
    float: right;
    /* width: 35%; */
}
.navbar ul li{
    list-style-type: none;
    display:  inline-block;
    width: auto;
    height: inherit;
    transition: 0.3s linear;
}
.navbar ul li a{
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-decoration: none;
    color:white;
    font-size: 1rem;
    letter-spacing: 0.5px;
    transition: 0.3s linear;
}
.navbar ul li:hover a,
.navbar ul li.active a{
    background: white;
    color:#EBD671;
}

.hamburger{
    display: none;
} @media screen and (max-width: 770px){
    .hamburger{
        display: flex;
        padding-top: 5px;
        margin-right: 10px;
        z-index: 10;
    }
} 
