/* Default styles */
.about {
  height: 100vh;
  width: 100%;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('./rawdah-assets/about.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.about .about-info {
  flex-direction: column;
  margin-top: 70px;
  max-width: 1500px;
}

.about-container .about-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.about .about-info .ourVision {
  position: absolute;
  margin-top: 50px;
  bottom:400px;
  
  left: 50%;
  transform: translateX(-50%);
}

.about-container .about .about-info .ourVision-desc{
  position: absolute;
  bottom: 350px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  max-width: 15000px;

}


.about-container .about .about-info .ourMissionTitle {
  position: absolute;
  margin-top: 50px;
  bottom:650px;
  
  left: 50%;
  transform: translateX(-50%);
}

.about .ourMissionDesc{
  position: absolute;
  margin-top: 50px;
  bottom:600px;
  
  left: 50%;
  transform: translateX(-50%);
}
.about-container .about .about-info .ourMissionDesc {
  color: blue;
}








